var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InputUUIDProvider", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var uuid = ref.uuid
          return [
            _c(_vm.styleComponent, {
              ref: "activator",
              tag: "Component",
              attrs: {
                "data-cy": _vm.dataCy,
                focused: _vm.isFocused,
                error: _vm.isError,
                disabled: _vm.disabled,
                alignment: _vm.alignment,
                size: _vm.size,
                height: _vm.height,
                "details-label": _vm.informationLabel
              },
              on: { mousedown: _vm.onMouseDown, mouseup: _vm.onMouseUp },
              nativeOn: {
                keydown: function($event) {
                  return _vm.onKeyDown.apply(null, arguments)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var inputReference = ref.inputReference
                      return [
                        _c(
                          "InputController",
                          [
                            _vm._t("prepend"),
                            _vm._v(" "),
                            _c("InputSelectValue", {
                              attrs: {
                                "data-cy": _vm.dataCy + "-value",
                                size: _vm.size,
                                alignment: _vm.alignment,
                                disabled: _vm.disabled,
                                value: _vm.presentingValue,
                                wrap: _vm.wrapValue
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "value",
                                    fn: function() {
                                      return [_vm._t("value")]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c("input", {
                              ref: "input",
                              class: _vm.classes,
                              attrs: {
                                id: uuid,
                                placeholder: _vm.placeholderValue,
                                disabled: _vm.disabled,
                                "aria-label": _vm.label || "no description",
                                type: "text",
                                readonly: ""
                              },
                              on: { focus: _vm.onFocus, blur: _vm.onBlur }
                            }),
                            _vm._v(" "),
                            _vm.label
                              ? _c("InputLabel", {
                                  attrs: {
                                    for: uuid,
                                    required: _vm.required,
                                    size: _vm.size,
                                    floating:
                                      _vm.isFocused || _vm.hasAnyValueSelected,
                                    focused: _vm.isFocused,
                                    disabled: _vm.disabled,
                                    error: _vm.isError,
                                    icon: _vm.icon,
                                    label: _vm.label
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._t("append"),
                            _vm._v(" "),
                            _vm.isError
                              ? _c("ErrorHint", {
                                  attrs: { hint: _vm.errorMessages }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("IconArrowDropdown", {
                              attrs: { state: _vm.dropDownState }
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.isReadyToRender
                          ? _c("SelectDropdown", {
                              ref: "menu",
                              attrs: {
                                value: _vm.value,
                                "parent-reference": inputReference,
                                "data-cy": _vm.dataCy + "-dropdown",
                                fixed: _vm.fixedContent,
                                size: _vm.size,
                                multiselect: _vm.multiselect,
                                clearable: _vm.clearable,
                                "fixed-content": _vm.fixedContent,
                                searchable: _vm.searchable,
                                options: _vm.options,
                                "search-value": _vm.searchValue,
                                "option-key": _vm.optionKey,
                                "option-value": _vm.optionValue,
                                "is-visible": _vm.isFocused
                              },
                              on: {
                                dismiss: _vm.onDismiss,
                                clear: _vm.onClear,
                                search: _vm.onSearch,
                                input: _vm.onSelectValue,
                                "click-outside": _vm.onClickOutside
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "body",
                                    fn: function() {
                                      return [_vm._t("dropdownBody")]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "noDataPlaceholder",
                                    fn: function() {
                                      return [_vm._t("noDataPlaceholder")]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "noResultsPlaceholder",
                                    fn: function() {
                                      return [_vm._t("noResultsPlaceholder")]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "dropdown",
                                    fn: function() {
                                      return [
                                        _vm._t("dropdown", null, {
                                          onSelectValue: _vm.onSelectValue,
                                          onApply: _vm.onDismiss,
                                          onClear: _vm.onClear
                                        })
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var index = ref.index
                                      var item = ref.item
                                      var isSelected = ref.isSelected
                                      var isSmallSize = ref.isSmallSize
                                      return [
                                        _vm._t("option", null, {
                                          option: item,
                                          isSelected: isSelected,
                                          isSmallSize: isSmallSize,
                                          index: index
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "details",
                    fn: function() {
                      return [_vm._t("details")]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }